import * as React from 'react';
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Translate } from '@hanssens/ht-translate';
import { ResizeDetector, useResize } from '@hanssens/ht-resize';
import { useHTStore } from '@hanssens/ht-store';

import CustomerInfo from '../components/CustomerInfo';
import CustomerLogo from '../components/CustomerLogo';
import TopDeskTicket from '../components/TopDeskTicket';
import userApi from '../api/userApi';

import { ITicket as ITopDeskTicket } from '../interfaces/ITopDeskTicket';
import { ITicket } from '../interfaces/ITicket';

// Logos
import tOPdeskLogoRGB from '../assets/images/TOPdesk_RGB_Logomark.svg';
import tOPdeskLogoWhite from '../assets/images/TOPdesk_White_Logomark.svg';

interface IMainProps {}

export interface IUser {
    id: string;
    firstName: string;
    surName: string;
    mobileNumber: string;
    phoneNumber: string;
    branch: IBranch | null;
}

export interface IBranch {
    id: string;
    name: string;
}

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const Main: React.FC<IMainProps> = (props) => {
    const [searchParams] = useSearchParams();

    const [user, setUser] = useHTStore<IUser | null>('user');
    const [logo, setLogo] = useHTStore<string | null>('logo');
    const [tickets, setTickets] = useHTStore<ITopDeskTicket[] | null>('tickets');
    const [newTicket, setNewTicket] = useHTStore<ITicket>('newTicket');
    const [newCallback, setNewCallback] = useHTStore<ITicket>('newCallback');

    const id = searchParams.get('id');
    const number = searchParams.get('number');

    const navigate = useNavigate();
    const { getClasses } = useResize();

    /***********************************************************************
     * Startup
     ***********************************************************************/

    React.useEffect(() => {
        if (id && guidRegex.test(id)) {
            userApi
                .get(`user/${id}`)
                .then((response) => {
                    setUser(response.data);
                })
                .catch((err) => {
                    setUser({
                        id: '',
                        firstName: 'Onbekend',
                        surName: 'Onbekend',
                        phoneNumber: number ?? '',
                        mobileNumber: '',
                        branch: null,
                    });
                    console.warn(err);
                });
        } else {
            setUser({
                id: '',
                firstName: 'Onbekend',
                surName: 'Onbekend',
                phoneNumber: number ?? '',
                mobileNumber: '',
                branch: null,
            });
        }
    }, [id, setUser, number]);

    React.useEffect(() => {
        if (id && guidRegex.test(id)) {
            userApi
                .get(`user/${id}/logo`)
                .then((response) => {
                    setLogo(response.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [id, setLogo]);

    React.useEffect(() => {
        if (id && guidRegex.test(id)) {
            userApi
                .get(`user/${id}/tickets`)
                .then((response) => {
                    setTickets(response.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [id, setTickets]);

    // Init new ticket
    React.useEffect(() => {
        if (id && guidRegex.test(id) && !newTicket && user) {
            setNewTicket({
                callerId: id,
                firstName: user.firstName,
                surName: user.surName,
                phoneNumber: number,
                briefDescription: '',
                callTypeId: null,
                operatorId: null,
                description: '',
                durationId: null,
            });
        } else if ((!id || !guidRegex.test(id)) && !newTicket) {
            setNewTicket({
                callerId: null,
                firstName: '',
                surName: '',
                phoneNumber: number === 'anonymous' ? '' : number,
                briefDescription: '',
                callTypeId: null,
                operatorId: null,
                description: '',
                durationId: null,
            });
        }
    }, [id, newTicket, user, setNewTicket, number]);

    // Init Callback request
    React.useEffect(() => {
        if (id && guidRegex.test(id) && !newCallback && user) {
            setNewCallback({
                callerId: id,
                firstName: user.firstName,
                surName: user.surName,
                phoneNumber: number,
                briefDescription: 'Terugbelverzoek',
                callTypeId: 'a5172dae-ff11-4dfa-8a45-babb9ea7ef51',
                operatorId: null,
                description: `Gelieve terug te bellen naar ${user.firstName} ${user.surName} op ${number}`,
                durationId: '9da47a4e-909e-4fcc-a273-7b3294d0cc51',
            });
        } else if ((!id || !guidRegex.test(id)) && !newCallback) {
            setNewCallback({
                callerId: null,
                firstName: '',
                surName: '',
                phoneNumber: number === 'anonymous' ? '' : number,
                briefDescription: 'Terugbelverzoek',
                callTypeId: 'a5172dae-ff11-4dfa-8a45-babb9ea7ef51',
                operatorId: null,
                description: '',
                durationId: '9da47a4e-909e-4fcc-a273-7b3294d0cc51',
            });
        }
    }, [id, newCallback, user, number, setNewCallback]);

    /***********************************************************************
     * Event handlers
     ***********************************************************************/
    const handleNewTicketClicked = () => {
        let params: Record<string, string> = {
            id: id ?? 'null',
        };

        if (number) {
            params = {
                ...params,
                number: number,
            };
        }

        navigate({
            pathname: '/ticket/create',
            search: createSearchParams(params).toString(),
        });
    };
    const handleNewCallbackRequestClicked = () => {
        let params: Record<string, string> = {
            id: id ?? 'null',
        };

        if (number) {
            params = {
                ...params,
                number: number,
            };
        }

        navigate({
            pathname: '/callback/create',
            search: createSearchParams(params).toString(),
        });
    };

    /***********************************************************************
     * Render
     ***********************************************************************/

    let ticketContent = null;
    if (tickets && tickets.length > 0) {
        ticketContent = (
            <>
                <div className='d-flex flex-row align-items-center'>
                    <img src={tOPdeskLogoRGB} height='12px' className='mx-2' alt={'TOPdesk-logo-RGB'} />
                    <span className='fw-bold'>
                        <Translate id='main.ticket.recent' />
                    </span>
                </div>

                <div className='px-2 ticket-container scrollable-y'>
                    <div className='inner'>
                        {tickets.map((t, i) => {
                            let classNames = ['bg-white', 'box-shadow'];

                            if (i > 0) {
                                classNames.push('mt-3');
                            }

                            return <TopDeskTicket key={t.id} className={classNames.join(' ')} ticket={t} />;
                        })}
                    </div>
                </div>
            </>
        );
    }

    return (
        <section id='content' className='h-100 p-3'>
            <ResizeDetector />
            <div className='bg-white w-100 h-100'>
                <div className='content-container pb-3 h-100'>
                    {/* Customer info */}
                    <div className='row h-100'>
                        <div className={getClasses({ shared: ['h-100'], xs: ['col-4'], md: ['col-4'], lg: ['col-3'] })}>
                            <div className='d-flex flex-column h-100'>
                                <div id='info' className='d-flex flex-column'>
                                    <div className='d-flex flex-row mb-3'>
                                        <i className='fas fa-user text-green' />
                                        <div className='fw-bold ms-3'>
                                            <Translate id='main.contact' />
                                        </div>
                                    </div>

                                    <div className='mb-4'>
                                        <CustomerLogo logo={logo} />
                                        <CustomerInfo user={user} />
                                    </div>
                                </div>
                                {/* Buttons */}
                                <div id='buttons' className='mt-auto'>
                                    <div className='d-flex flex-column'>
                                        <Button variant='contained' className='rounded-0' onClick={handleNewTicketClicked} disabled={!newTicket}>
                                            <div className='d-flex flex-row align-items-center w-100'>
                                                <img src={tOPdeskLogoWhite} alt='logo-topdesk' className='logo-topdesk' />
                                                <span className='ms-3 text-start'>
                                                    <Translate id='main.newTicket' />
                                                </span>
                                            </div>
                                        </Button>
                                        <Button variant='contained' className='rounded-0 mt-3' onClick={handleNewCallbackRequestClicked} disabled={!newCallback}>
                                            <div className='d-flex flex-row align-items-center w-100'>
                                                <img src={tOPdeskLogoWhite} alt='logo-topdesk' className='logo-topdesk' />
                                                <span className='ms-3 text-start'>
                                                    <Translate id='main.newCallback' />
                                                </span>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={getClasses({ shared: ['h-100'], xs: ['col-8'], md: ['col-8'], lg: ['col-9'] })}>{ticketContent}</div>
                    </div>
                </div>
                {/* Footer */}
                <div id='footer' className='w-100 d-flex flex-row'>
                    <div className='mt-auto ms-auto text-hanssens'>powered by Hanssens Beyond Telecom</div>
                </div>
            </div>
        </section>
    );
};

export default Main;

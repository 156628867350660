import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslate } from '@hanssens/ht-translate';

import { ICallType } from '../components/NewTopDeskTicket';
import userApi from '../api/userApi';

import Main from '../pages/Main';
import NewTopDeskTicket from '../components/NewTopDeskTicket';
import NewCallbackRequest from '../components/NewCallbackRequest';

import mainTranslations from '../translations/main.json';

interface IMainRouterProps {}

const MainRouter: React.FC<IMainRouterProps> = (props) => {
    /***********************************************************************
     * State
     ***********************************************************************/
    const [callTypes, setCallTypes] = React.useState<ICallType[] | null>(null);

    useTranslate(mainTranslations);

    /***********************************************************************
     * Init
     ***********************************************************************/
    React.useEffect(() => {
        userApi
            .get('ticket/callTypes')
            .then((response) => {
                setCallTypes(response.data);
            })
            .catch((err) => {});
    }, []);

    /***********************************************************************
     * Render
     ***********************************************************************/

    return (
        <Routes>
            <Route path='/callback/create' element={<NewCallbackRequest callTypes={callTypes} />} />
            <Route path='/ticket/create' element={<NewTopDeskTicket callTypes={callTypes} />} />

            {/* General */}
            <Route path='/*' element={<Main />} />

            {/* REDIRECT */}
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
};

export default MainRouter;
